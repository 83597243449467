.container {
    font-size: small;
    display: flex;
    flex-direction: column;
    // align-items: stretch;
    // justify-content: space-between;
    justify-content: center;
    height: 100vh; /* Adjust the height as needed */
    
    .column-title,
    .column-datas,
    .column-chatbot {
    //   flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .box {
        // width: 30%;
        // padding: 20px;
        border: 1px solid #000;
        text-align: center;
        white-space: nowrap;
      }
    }
    
    .column-datas {
    //   border-top: 1px dotted #000;
    //   border-bottom: 1px dotted #000;
      
      .row {
        display: flex;
        justify-content: space-between;
        
        .sub-box1{
            flex: 5;
            padding: 20px;
            display: flex;
            flex-direction: column;
            text-align: center;
            // border: 1px solid #000;
          }
        .sub-box2 {
          flex: 3;
          padding: 20px;
          display: flex;
          flex-direction: column;
          text-align: center;
        //   border: 1px solid #000;
        }
        .sub-box3 {
            flex: 2;
            padding: 20px;
            display: flex;
            flex-direction: column;
            text-align: center;
          //   border: 1px solid #000;
          }
        
        .sub-box1 {
          border-right: 1px dotted #000;
        }

      }
    }
  }
  
  .data-key, .data-value, .data-unit{
    flex:1;
    padding: 5px;    
  }

  input{
    width: 100%;
    text-align: center;
  }
  