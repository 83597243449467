.cont{

    display: flex;
    align-items: center;

    .box{
        // width:30vw;
        // height: 30vh;
        border: 1px solid black;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .loadDataBtn{
            margin: 2% 0% 2% 0%;

        }
    }
}