.chatbot-container {
    display: flex;
    flex-direction: column;
    height: 90vh;
  .message-container {
    flex:9;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    overflow: scroll;
    overflow-x: hidden;

    .message {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      max-width: 70%;
      text-align: left;
      white-space: pre-line;
    }

    .system {
      display: none;
      background-color: gray;
      align-self: center;
    }

    .user {
      background-color: dodgerblue;
      align-self: flex-end;
    }

    .bot {
      background-color: darkorange;
      align-self: flex-start;
    }
  }

  .input-container {
    flex:1;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    input[type="text"] {
      flex-grow: 1;
      padding: 10px;
      border-radius: 5px;
      border: none;
      margin-right: 10px;
      font-size: 16px;
    }

    button {
      width: 25vw;
      padding: 15px 20px;
      border-radius: 5px;
      border: none;
      background-color: dodgerblue;
      color: white;
      font-weight: bold;
      cursor: pointer;
      
      transition: background-color 0.3s ease;

      &:hover {
        background-color: royalblue;
      }
    }
  }
}



  
  