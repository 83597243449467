.container{
    // border: 1px solid black;
    display: flex;
    align-items: center;
    text-align: center;
    .controlBox{
        width: 90vw;
        height: 100%;
        flex:1;
        display:flex;
        flex-direction: column;
        
        .titleHeader{
            flex:1;
            margin: 5px;
            padding: 5px;
            // border: 1px solid black;
            font-size: 0.7rem;
            color:purple

        }
        .titleMain{
            flex:1;
            height: 100%;       
        }
        .main{
            height: 100%;       
            flex:8;
            // overflow: scroll;
            // overflow-x: hidden;
        }
        .userPrompt{
            height: 100%;       
            flex:1;
            

        }
    }
}