/* components/LoginForm.module.scss */

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;    
    
  
    .formGroup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
    
  
      label {
        color: #555;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }
  
      input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        width: 300px;
      }
    }
  
    button {
      padding: 10px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0062cc;
      }
    }
  }